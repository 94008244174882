import React from 'react';

function App() {
  return (
    <div className="landing">
      <img src={require("./logo.svg")} alt="Hydrology"/>
      <p>&copy; Green Supplier Technologies Limited (12551795)</p>
    </div>
  );
}

export default App;
